import { Router } from "@reach/router"
import { navigate } from "gatsby"
import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import Layout from "../../components/layout/store/Layout"
import DashboardView from "../../components/views/dashboard/store/dashboard"
const Routes = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Layout>
        <DashboardView />
      </Layout>
    </DndProvider>
  )
}

export default Routes