import React, { useEffect, useState } from "react"
import { Legend, Pie, PieChart, ResponsiveContainer } from "recharts"
import * as RadixPopover from "@radix-ui/react-popover"
import { convertNumberToDottedString } from "../../../../utils/number"
import moment from "moment"

interface IBaseProps {
  children?: React.ReactNode
}

interface IViewProps extends IBaseProps { }

interface ICardProps extends IBaseProps {
  image?: string
  name?: string
  number?: number
}

interface IBigCardProps extends IBaseProps { }

interface IOverallAppointmentProps extends IBaseProps { }

interface IStaticBar extends IBaseProps {
  value?: number
}

interface IEmployeeCardProps extends IBaseProps {
  name?: string
  image?: string
  title?: string
  incoming?: number
  shift?: string
}

interface IStylistCardProps extends IBaseProps {
  name?: string
  image?: string
  title?: string
  type?: string
}

const data01 = [
  {
    name: "demo",
    value: 100,
  },
  {
    name: "demo2",
    value: 100,
  },
]

const Card: React.FC<ICardProps> = (props: ICardProps) => {
  return (
    <React.Fragment>
      <div className="grid grid-cols-5 gap-x-2 bg-white rounded-lg p-2 shadow-sm">
        <div className="col-span-2 flex justify-center items-center">
          <div className=" w-[70px] h-[70px] overflow-hidden rounded-full border-gray-300 border bg-white m-auto">
            <img
              src={props.image}
              className="min-h-[70px] object-cover  max-h-[70px] aspect-square"
            />
          </div>
        </div>
        <div className="col-span-2 flex items-center">
          <div>
            <div className="capitalize font-bold text-xs">{props.name}</div>
            <div className="font-semibold mt-2 text-base">
              {convertNumberToDottedString(props.number as number)}
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <a href="#">...</a>
        </div>
      </div>
    </React.Fragment>
  )
}

const BigCard: React.FC<IBigCardProps> = (props: IBigCardProps) => {
  return (
    <>
      <div className="bg-white rounded-xl p-2 shadow-sm h-full">
        <div className="font-bold p-2 text-base">Gender</div>
        <div className="p-2 h-full">
          <ResponsiveContainer height="80%">
            <PieChart width={730} height={250} className="col-span-3 grid">
              <Pie
                data={data01}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={50}
                fill="#8884d8"
              />
              <Legend height={-10} layout="horizontal" />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  )
}

const OverallAppointment: React.FC<IOverallAppointmentProps> = (
  props: IOverallAppointmentProps
) => {
  const [progress, setProgress] = React.useState(13)
  return (
    <>
      <div className="relative bg-white rounded-xl p-4 shadow-sm h-full">
        <div className="font-bold text-base">Overall Appointments</div>
        <div className="p-5 grid auto-cols-auto grid-flow-col gap-x-12 overflow-x-scroll">
          {props.children}
        </div>
      </div>
    </>
  )
}

const StaticBar: React.FC<IStaticBar> = (props: IStaticBar) => {
  const [progress, setProgress] = React.useState(props.value || 0)
  // const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    setProgress((200 * (props.value || 0)) / 100)
  }, [])
  return (
    <>
      {!false && (
        <div>
          <div className="flex justify-center">
            <div className=" bg-gray-200 h-[200px] dark:bg-gray-200 w-6 flex items-end">
              <div
                className={`bg-blue-400 w-6  `}
                style={{
                  height: progress,
                }}
              ></div>
            </div>
          </div>
          <div className="text-center text-xs font-bold mt-1">{"10:31"}</div>
        </div>
      )}
    </>
  )
}

const UpcomingAppointments = ({ }) => {
  const [chosenDate, setChosenDate] = useState(moment().startOf("date"))
  const [week, setWeek] = useState(moment().startOf("week"))
  const [datesRange, setDatesRange] = useState<moment.Moment[]>([])

  useEffect(() => {
    let dates = [week.clone()]
    for (let i = 1; i < 7; i++) {
      dates.push(week.clone().add(i, "days"))
      setDatesRange([...dates])
    }
  }, [week])

  const getDateInWeek = (date: number) => {
    const dates = {
      0: "Sun",
      1: "Mon",
      2: "Tue",
      3: "Wed",
      4: "Thu",
      5: "Fri",
      6: "Sat",
    }

    return dates[+date]
  }

  return (
    <>
      <div className="relative bg-white rounded-xl p-4 shadow-sm h-full">
        <div className="font-extrabold text-xl">Overall Appointments</div>
        <div className="flex justify-between">
          <div className="font-bold text-sm my-2 mt-4">
            {week.clone().format("MMMM YYYY")}
          </div>
          <div>
            <button
              className=" rounded-md p-2"
              onClick={() => setWeek(week.clone().subtract(1, "week"))}
            >
              {"<"}
            </button>
            <button
              className=" rounded-md p-2"
              onClick={() => setWeek(week.clone().add(1, "week"))}
            >
              {">"}
            </button>
          </div>
        </div>

        <div className="grid auto-cols-auto grid-flow-col gap-x-2 mt-[5px]">
          {datesRange.map((item: moment.Moment, index: number) => {
            return (
              <div
                onClick={() => {
                  setChosenDate(item.clone())
                }}
                className={`${chosenDate.isSame(item.clone())
                    ? "!bg-blue-500 text-white"
                    : ""
                  } p-2  bg-gray-200 rounded-md shadow-md text-center cursor-pointer`}
                key={index}
              >
                <div>{getDateInWeek(item.clone().weekday())}</div>
                <div className="font-bold text-xs ">
                  {item.clone().format("DD")}
                </div>
              </div>
            )
          })}
        </div>

        <div className="mt-6 overflow-y-scroll " style={{ maxHeight: 800 }}>
          {Array.from(Array(30).keys()).map((i, index) => {
            return (
              <EmployeeCard
                image="https://cdn-icons-png.flaticon.com/512/3048/3048127.png"
                name="Nguyễn Văn A"
                title="Doctor"
                shift="10:30 - 11:30"
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

const EmployeeCard: React.FC<IEmployeeCardProps> = (
  props: IEmployeeCardProps
) => {
  return (
    <React.Fragment>
      <div className="grid grid-cols-8 border border-gray-200 rounded-md p-2 mt-3">
        <div className="col-span-2">
          <div className=" w-[70px] h-[70px] overflow-hidden rounded-full border-gray-300 border bg-white m-auto">
            <img
              src={props.image}
              className="min-h-[70px] object-cover  max-h-[70px] aspect-square"
            />
          </div>
        </div>
        <div className="col-span-5">
          <div className="text-md font-semibold">{props.name || "N/A"}</div>
          <div className="text-xs font-medium">{props.title || "N/A"}</div>
          <div className=" text-xs">{props.shift || "N/A"}</div>
        </div>
        <div className="text-center ">
          <button className="  text-gray-500 rounded-md p-2">{"..."}</button>
          <div className="text-gray-500">{props.incoming || 0}</div>
        </div>
      </div>
    </React.Fragment>
  )
}

const StylistCard: React.FC<IStylistCardProps> = (props: IStylistCardProps) => {
  return (
    <React.Fragment>
      <div className="grid grid-cols-8 border border-gray-200 rounded-md p-2 mt-3">
        <div className="col-span-2">
          <div className=" w-[70px] h-[70px] overflow-hidden rounded-full border-gray-300 border bg-white m-auto">
            <img
              src={props.image}
              className="min-h-[70px] object-cover  max-h-[70px] aspect-square"
            />
          </div>
        </div>
        <div className="col-span-5">
          <div className="font-bold">{props.name || "N/A"}</div>
          <div className="font-medium text-xs">{props.title || "N/A"}</div>
          <div className="text-xs">{props.type || "N/A"}</div>
        </div>
        <div className="text-center">
          <button className=" text-gray-500 rounded-xl p-2">{"..."}</button>
          {/* <div className="text-gray-500">
            {props.incoming || 0}
          </div> */}
        </div>
      </div>
    </React.Fragment>
  )
}

const NailDashboardView: React.FC<IViewProps> = (props: IViewProps) => {
  return (
    <div className="grid grid-cols-6 gap-4 auto-rows-auto">
      <div className="col-span-4">
        <div className="grid grid-cols-3 col-span-3 gap-4 grid-rows-2">
          <Card
            name={"Nguyen Van A"}
            image={"https://cdn-icons-png.flaticon.com/512/3048/3048127.png"}
            number={1000}
          />
          <Card
            name={"name"}
            image={"https://cdn-icons-png.flaticon.com/512/3048/3048127.png"}
            number={1000}
          />
          <div className="row-span-2">
            <BigCard />
          </div>

          <Card
            name={"name"}
            image={"https://cdn-icons-png.flaticon.com/512/3048/3048127.png"}
            number={1000}
          />
          <Card
            name={"name"}
            image={"https://cdn-icons-png.flaticon.com/512/3048/3048127.png"}
            number={1000}
          />
        </div>
      </div>

      <div className=" col-span-2 row-span-3">
        <UpcomingAppointments />
      </div>

      <div className="col-span-4">
        <OverallAppointment>
          {Array.from(Array(12).keys()).map((i, index) => {
            return <StaticBar key={index} value={78} />
          })}
        </OverallAppointment>
      </div>

      <div className="col-span-4 grid grid-cols-8 gap-4">
        <div className="col-span-4 bg-white shadow-sm rounded-xl py-4">
          <div className="font-bold px-4">Stylists</div>

          <div
            className="overflow-y-scroll px-4"
            style={{
              minHeight: 300,
              maxHeight: 300,
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 120].map((i, index) => {
              return (
                <StylistCard
                  key={index}
                  name="Trần Thị B"
                  image="https://cdn-icons-png.flaticon.com/512/3048/3048127.png"
                  title="Nail Artist"
                  type="Full Time"
                />
              )
            })}
          </div>
        </div>

        <div className="col-span-4 bg-white shadow-sm rounded-xl p-4">
          <div className="font-bold text-base">Financial Goal</div>
        </div>
      </div>
    </div>
  )
}

export default NailDashboardView